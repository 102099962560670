import VanillaTilt from 'vanilla-tilt'

var ua = window.navigator.userAgent;
var msie = ua.indexOf("MSIE ");
var isMobile = {
	Android: function () {
		return navigator.userAgent.match(/Android/i);
	}, BlackBerry: function () {
		return navigator.userAgent.match(/BlackBerry/i);
	}, iOS: function () {
		return navigator.userAgent.match(/iPhone|iPad|iPod/i);
	}, Opera: function () {
		return navigator.userAgent.match(/Opera Mini/i);
	}, Windows: function () {
		return navigator.userAgent.match(/IEMobile/i);
	}, any: function () {
		return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
	}
};

// function vanillaTilt(item, max, speed) {
// 	let items = document.querySelectorAll(item)
// 	VanillaTilt.init((items), {max: max, speed: speed});
// }
//
// vanillaTilt('.cards__item', 5, 900);
// vanillaTilt('.cost__slot', 5, 900);
// vanillaTilt('.b0__item', 5, 900);
// vanillaTilt('.masters__item', 5, 900);
// vanillaTilt('.how__item', 5, 900);
// vanillaTilt('.block2__item', 5, 900);
// vanillaTilt('.services-block2__item', 5, 900);
// vanillaTilt('.service-block6__item', 5, 900);

import {Fancybox} from "@fancyapps/ui";

Fancybox.bind("[data-fancybox]", {});

$('.service-block6__link').parallax({
	mouseport: $('.service-block6__item'),
	decay: 0
});

new Swiper('.services-block4__slider', {
	navigation: {
		nextEl: '.button-next',
		prevEl: '.button-prev',
	},
	pagination: {
		el:
			'.swiper-pagination',
		clickable: true,
	},
	breakpoints: {
		320: {
			// slidesPerView: 1,
			spaceBetween: 30,
			slidesPerView: "auto",
			centeredSlides: true,
		},
		600: {
			slidesPerView: 2,
			spaceBetween: 10
		},
		1024: {
			slidesPerView: 3,
			spaceBetween: 30,
		}
	}
});

new Swiper('.services-block5__slider', {
	navigation: {
		nextEl: '.button-next2',
		prevEl: '.button-prev2',
	},
	breakpoints: {
		320: {
			spaceBetween: 30,
			slidesPerView: "auto",
			centeredSlides: true,
		},
		600: {
			slidesPerView: 2,
			spaceBetween: 10
		},
		1024: {
			slidesPerView: 3,
			spaceBetween: 30,
		}
	}
});
